import React from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Image,
  ListItem,
  Stack,
  Flex,
  UnorderedList,
} from "@chakra-ui/react";
import RichText from "../PrismicRichText";
const VIDEO_IFRAME_SEARCH_PARAMS = "?autoplay=1&controls=1&enablejsapi=1&rel=0";
const Description = ({ data }) => {
  return (
    <Box
      maxW="48.25rem"
      borderBottom={{ base: "1px", md: "0px" }}
      borderColor={{ base: "border.100" }}
      pb={{ base: "3.125rem" }}
    >
      <VStack spacing="6" mb={{ base: "6", sm: "3.75rem" }}>
        <Image
          objectFit="cover"
          borderRadius="16px"
          width={{ base: "100%", sm: "772px" }}
          height={{ base: "200px", sm: "334px" }}
          mb="3.125rem"
          src={data.image}
        />
        <Heading as="h2" fontSize="xl">
          {data.title_1}
        </Heading>
        <Box fontSize="sm" color="grey.100">
           <RichText text={data.description}/>
        </Box>
        <Image
          objectFit="cover"
          borderRadius="16px"
          width={{ base: "100%", sm: "772px" }}
          height={{ base: "200px", sm: "259px" }}
          src={data.secondImage}
        />
      </VStack>
      <Heading fontSize="xl" mb="5">
        {data.title_2}
      </Heading>
      <Box fontSize="sm" color="grey.100" mb={{ base: "6", sm: "2.5rem" }}>
         <RichText text={data.description_1}/>
      </Box>
      <Stack spacing="2.375rem" mb={{ base: "2.5rem" }}>
        <Flex direction={{ base: "column", md: "row" }}>
          <Image
            objectFit="cover"
            borderRadius="20px"
            width={{ base: "100%", sm: "272px" }}
            height={{ base: "200px", sm: "259px" }}
            src={data.thirdImage}
          />
          <UnorderedList
            sx={{
              "& li::marker": {
                fontSize: "20px",
                color: "green.200",
              },
            }}
            ml="10"
            maxW="433px"
            spacing={3}
            mt={{ base: "2.25rem", md: "0" }}
          >
            {data.lists?.map((list,idx) => (
                <ListItem key={`list-${list}-${idx}`} fontSize="sm" color="grey.100">
                  {list}
                </ListItem>
            ))}
          </UnorderedList>
        </Flex>
      </Stack>
      <Box fontSize="sm" color="grey.100" mb={{ base: "6", sm: "2.31rem" }}>
         <RichText text={data.description_2}/>
      </Box>
      <Box
        as="iframe"
        w="full"
        mb="3.175rem"
        h={{ base: "194px", sm: "410px" }}
        src={`${data.videoUrl}${VIDEO_IFRAME_SEARCH_PARAMS}`}
        borderRadius="16px"
      />
      <Box fontSize="sm" color="grey.100">
         <RichText text={data.description_3}/>
      </Box>
    </Box>
  );
};

export default Description;
