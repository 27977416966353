import * as React from "react";
import { Container, Heading, Stack } from "@chakra-ui/react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Seo from "../../components/SEO";
import Main from "../../components/layouts/Main";
import SmallNav from "../../components/smallNav";
import Description from "../../components/financeOrganization/Description";
import UsefulLinks from "../../components/usefulLinks";
import usePrismicFinanceOrganization from "../../hooks/usePrismicFinanceOrganization";
import usePrismicSubmitClaim from "../../hooks/usePrismicSubmitClaim";


const FinanceOrganization = () => {
  const { language } = useTranslation();
  const data = usePrismicFinanceOrganization();
  const data1 = usePrismicSubmitClaim();

  return (
    <Main>
      <Container maxW="7xl" px="5" mb="3.75rem">
        <Seo
          title={data.navText.split('/').pop()}
          description={data.title}
          link={`https://www.fsm.am/${language}/Նկարագրություն`}
        />
        <SmallNav>
            {" "}
            {data.navText}
        </SmallNav>
        <Heading
          as="h1"
          maxW="48.25rem"
          fontSize={{ base: "2xl", sm: "3xl" }}
          fontWeight="900"
          mt={{ base: "3", sm: "5" }}
          mb="10"
        >
          {data.title}
        </Heading>
        <Stack direction={{ base: "column", md: "row" }} spacing="6">
          <Description data={data} />
          <UsefulLinks usefulLinks={data1.usefulLinks} />
        </Stack>
      </Container>
    </Main>
  );
};

export default FinanceOrganization;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
