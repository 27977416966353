import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next'

const usePrismicFinanceOrganization = () => {
    const { language } = useI18next()
    const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

    const response = useStaticQuery(graphql`
    query PrismicFinanceOrganization {
      allPrismicFinanceorganization(filter: {tags: {in: "finance_organization"}}) {
        nodes {
          lang
          data {
            navtext
            title {
              text
            }
            title_1 {
              text
            }
            title_2 {
              text
            }
            videourl {
              url
            }
            description {
              richText
            }
            description_1 {
              richText
            }
            description_2 {
              richText
            }
            description_3 {
              richText
            }
            lists {
              list
            }
            image {
              url
            }
            secondimage {
              url
            }
            thirdimage {
              url
            }
            title1 {
              text
            }
            linkgroup {
              linktitle
            }
            description1 {
              richText
            }
          }
        }
      }
    }
  `);
    return sanitize(response.allPrismicFinanceorganization.nodes.filter(item => item.lang === newLang)[0].data)
};

const sanitize = (response) => {
    return {
        navText: response.navtext,
        title: response.title.text,
        title_1: response.title_1.text,
        title_2: response.title_2.text,
        description: response.description.richText,
        description_1: response.description_1.richText,
        description_2: response.description_2.richText,
        description_3: response.description_3.richText,
        videoUrl: response.videourl.url,
        image: response.image.url,
        secondImage: response.secondimage.url,
        thirdImage: response.thirdimage.url,
        lists: response.lists.map(list => list.list),
        usefulLinks: {
            title: response.title1.text,
            description: response.description1.richText,
            links: response.linkgroup.map(link => link.linktitle)
        }

    }
}

export default usePrismicFinanceOrganization;